import React from "react"

function Layout({ children }) {
  return (
    <div className="parentLayout">
      <div className="innerLayout">{children}</div>
    </div>
  )
}

export default Layout

import React, { Fragment } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Section,
  SectionTitle,
  Container,
  Banner,
  SubTitle,
} from "../styles/common"

import {
  // OurExpertise,
  // ExpertiseItem,
  // ExpertiseTitle,
  // ExpertiseDescription,
  MethodsDescription,
  MethodsItem,
  MethodsIcon,
  MethodsTitle,
  OurMethods,
  // OurExpertiseImg,
} from "../styles/whatwedo"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OurServices from "../components/ourServices"
import method1 from "../images/handcraft.png"
import method2 from "../images/devops.png"
import method3 from "../images/agile.png"
import ourExpertise from "../images/services/sketching-background.png"
import CommunityDriven from "../components/Components/CommunityDriven"
import imgPlace from "../images/whatwedo.png"
import TwoOnes from "../components/Components/TwoOnes"
import TwoOnesFlipped from "../components/Components/TwoOnesFlipped"
import HomeCube from "../components/homeCube"
import WhatWeDoAgile from "../components/Components/WhatWeDoAgile"
import StacksSlider from "../components/StacksSlider"

const ServicesPage = () => (
  <Layout>
    <Fragment>
      <SEO
        title="xHub | What we do"
        keywords={[
          "xhub",
          "devoxx",
          "devoxxmorocco",
          "devoxx4kids",
          "morocco",
          "casablanca",
          "x-hub.io",
          "devc",
          "Application & Technologies",
        ]}
      />
      {/* <Banner whatwedo overlay>
        <Container flex flexCenter>
          <ScrollAnimation
            style={{ width: "100%" }}
            duration={0.4}
            animateOnce={true}
            animateIn="slideInLeft"
          >
            <SectionTitle color="white" transform="uppercase" space="0">
              . What we do
            </SectionTitle>
            <SubTitle color="white">
              We commit to your digital challenges. We will be your partner to
              guarantee you the best services <br />& assistance for your
              projects for this we engage our expertise, methods & xPerts
            </SubTitle>
          </ScrollAnimation>
        </Container>
      </Banner> */}
      <Section bgcolor="#f6f6f6">
        <Container>
      <CommunityDriven title="What we do" description="We commit to your digital challenges. We will be your partner to
              guarantee you the best services & assistance for your
              projects for this we engage our expertise, methods & xPerts" img={imgPlace} />
            <HomeCube />
            </Container>
            </Section>
            <Section bgcolor="#fff">
            <Container>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <SectionTitle
              transform="uppercase"
              space="30px 0 20px"
              lightChild
              color="black"
              >
              Our <div>services</div>
            </SectionTitle>
          </ScrollAnimation>
          <OurServices />
          </Container>
            </Section>
            <Section bgcolor="#fff">
            <Container>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <SectionTitle
              transform="uppercase"
              lightChild
              color="black"
              >
              Our <div>technology stack</div>
            </SectionTitle>
          </ScrollAnimation>
          <StacksSlider />
          </Container>
            </Section>

            {/* <Section bgcolor="#fff">
            <Container>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
          <SectionTitle
              transform="uppercase"
              space="30px 0 20px"
              lightChild
              color="black"
              >
              Our <div>Expertise</div>
            </SectionTitle>
          </ScrollAnimation>
            <WhatWeDoAgile />
          </Container>
            </Section> */}

      <Section bgcolor="white">
        <Container>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <SectionTitle transform="none" lightChild color="black" space="30px 0 20px">
              Our <div>methods</div>
            </SectionTitle>
          </ScrollAnimation>

          <div className="paddingBottom60">
            <ScrollAnimation animateOnce={true} animateIn="slideInUp">
              <TwoOnes title="Agile" description="Our agile methodology developing and testing our client’s
                  products and services allows us to help them go through
                  requirements definition with the most flexible and responsive
                  way while absorbing the constant and rapid changes that comes." img={method3} />
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="slideInUp">
              <TwoOnesFlipped title="DevOps" description="We manage end-to-end engineering processes using DevOps
                  mindset and automation technics to foster collaboration
                  between Development teams and Operations and focusing on
                  constant testing and fast and efficient delivery and
                  deployment." img={method2} />
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <TwoOnes title="Craftmanship" description="The biggest part of our team are coding passionate, so
                  craftmanship is a natural choice for us. We strive developing
                  well-crafted and value-adding softwares and applications. We
                  love being part of craftmanship guildes and communities to
                  share our expertise and ideas on clean and valuable codes." img={method1} />
            </ScrollAnimation>
          </div>

          {/* <OurMethods>
            <MethodsItem>
              <ScrollAnimation
                animateOnce={true}
                delay={0}
                duration={0.5}
                animateIn="slideInUp"
              >
                <MethodsIcon src={method3} />
                <MethodsTitle>Agile</MethodsTitle>
                <MethodsDescription>
                  Our agile methodology developing and testing our client’s
                  products and services allows us to help them go through
                  requirements definition with the most flexible and responsive
                  way while absorbing the constant and rapid changes that comes.
                </MethodsDescription>
              </ScrollAnimation>
            </MethodsItem>
            <MethodsItem>
              <ScrollAnimation
                animateOnce={true}
                delay={0}
                duration={0.5}
                animateIn="slideInUp"
              >
                <MethodsIcon src={method2} />
                <MethodsTitle>DevOps</MethodsTitle>
                <MethodsDescription>
                  We manage end-to-end engineering processes using DevOps
                  mindset and automation technics to foster collaboration
                  between Development teams and Operations and focusing on
                  constant testing and fast and efficient delivery and
                  deployment.
                </MethodsDescription>
              </ScrollAnimation>
            </MethodsItem>
            <MethodsItem>
              <ScrollAnimation
                animateOnce={true}
                delay={0}
                duration={0.5}
                animateIn="slideInUp"
              >
                <MethodsIcon src={method1} />
                <MethodsTitle>Craftmanship</MethodsTitle>
                <MethodsDescription>
                  The biggest part of our team are coding passionate, so
                  craftmanship is a natural choice for us. We strive developing
                  well-crafted and value-adding softwares and applications. We
                  love being part of craftmanship guildes and communities to
                  share our expertise and ideas on clean and valuable codes.
                </MethodsDescription>
              </ScrollAnimation>
            </MethodsItem>
          </OurMethods> */}
        </Container>
      </Section>

      {/* <Section whatwedo>
        <OurExpertiseImg src={ourExpertise} alt="our expertise" />
        <Container>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <SectionTitle transform="none" color="white">
              Our expertise
            </SectionTitle>
          </ScrollAnimation>
          <OurExpertise>
            <ExpertiseItem>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <ExpertiseTitle>Backend</ExpertiseTitle>
                <ExpertiseDescription>
                  Building Enterprise applications involves security, accuracy,
                  redendency and performance challenges! we provide extensive
                  backend expertise for building crucial, specifically designed
                  applications.
                </ExpertiseDescription>
              </ScrollAnimation>
            </ExpertiseItem>
            <ExpertiseItem>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <ExpertiseTitle>Frontend</ExpertiseTitle>
                <ExpertiseDescription>
                  Following a user-centric approach, with our strong expertise
                  in Front end development on the latest trends and cutting-edge
                  technologies, we develop modern, interactive and intuitive web
                  experience!
                </ExpertiseDescription>
              </ScrollAnimation>
            </ExpertiseItem>
            <ExpertiseItem>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <ExpertiseTitle>Cloud</ExpertiseTitle>
                <ExpertiseDescription>
                  Cloud native is a key element in your digital transformation
                  and shift from legacy IT to the future. We guide you though
                  achieving faster deployment of better cloud-ready
                  applications.
                </ExpertiseDescription>
              </ScrollAnimation>
            </ExpertiseItem>
            <ExpertiseItem>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <ExpertiseTitle>Mobile</ExpertiseTitle>
                <ExpertiseDescription>
                  We design and develop native and high-quality custom mobile
                  applications for IOS and Android platforms. Our team has a
                  proven track record of sketching, building, and publishing
                  cutting edge, cross-platform mobile apps.
                </ExpertiseDescription>
              </ScrollAnimation>
            </ExpertiseItem>
            <ExpertiseItem>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <ExpertiseTitle>Big Data</ExpertiseTitle>
                <ExpertiseDescription>
                  There's no doubt that we're currently living in a world where
                  the most valuable resource is no longer oil, but data!
                  Following unique approaches, we support you in your journey to
                  store, manage, and analyze unstructured data for real business
                  gain.
                </ExpertiseDescription>
              </ScrollAnimation>
            </ExpertiseItem>
            <ExpertiseItem>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <ExpertiseTitle>AI</ExpertiseTitle>
                <ExpertiseDescription>
                  Whether you're exploring how to get started with Machine
                  Learning and Artificial intelligence, looking to deploy your
                  models in production or optimizing your models' performance,
                  we help you meet your goals.
                </ExpertiseDescription>
              </ScrollAnimation>
            </ExpertiseItem>
          </OurExpertise>
        </Container>
      </Section> */}
    </Fragment>
  </Layout>
)

export default ServicesPage

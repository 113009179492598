import React from "react"
import img1 from "../images/stacklogos/imgage1.png"
import img2 from "../images/stacklogos/imgage2.png"
import img4 from "../images/stacklogos/imgage4.png"
import img5 from "../images/stacklogos/imgage5.png"
import img6 from "../images/stacklogos/imgage6.png"
import img7 from "../images/stacklogos/imgage7.png"
import img8 from "../images/stacklogos/imgage8.png"
import img9 from "../images/stacklogos/imgage9.png"
import img10 from "../images/stacklogos/imgage10.png"
import img11 from "../images/stacklogos/imgage11.png"
import img12 from "../images/stacklogos/imgage12.png"
import img13 from "../images/stacklogos/imgage13.png"
import img14 from "../images/stacklogos/imgage14.png"
import img15 from "../images/stacklogos/imgage15.png"
import img16 from "../images/stacklogos/imgage16.png"
import img17 from "../images/stacklogos/imgage17.png"
import img18 from "../images/stacklogos/imgage18.png"
import img19 from "../images/stacklogos/imgage19.png"
import img20 from "../images/stacklogos/imgage20.png"
import img21 from "../images/stacklogos/imgage21.png"
import img22 from "../images/stacklogos/imgage10.png"
import img23 from "../images/stacklogos/imgage23.png"
import img24 from "../images/stacklogos/imgage24.png"
import img25 from "../images/stacklogos/imgage25.png"
import img26 from "../images/stacklogos/imgage26.png"
import img27 from "../images/stacklogos/imgage27.png"
import img28 from "../images/stacklogos/imgage28.png"
import img29 from "../images/stacklogos/imgage29.png"
import img30 from "../images/stacklogos/imgage30.png"
import img31 from "../images/stacklogos/imgage31.png"
import img32 from "../images/stacklogos/imgage32.png"
import img33 from "../images/stacklogos/imgage33.png"
import img34 from "../images/stacklogos/imgage34.png"
import img35 from "../images/stacklogos/imgage35.png"
import img36 from "../images/stacklogos/imgage36.png"
import img37 from "../images/stacklogos/imgage37.png"
import img38 from "../images/stacklogos/imgage38.png"
import img39 from "../images/stacklogos/imgage39.png"
import img40 from "../images/stacklogos/imgage40.png"
import img41 from "../images/stacklogos/imgage41.png"
import img42 from "../images/stacklogos/imgage42.png"
import img43 from "../images/stacklogos/imgage43.png"
import img44 from "../images/stacklogos/imgage44.png"
import img45 from "../images/stacklogos/imgage45.png"
import img46 from "../images/stacklogos/imgage46.png"
import arrowLeft from "../images/leftArrow.svg"
import arrowRight from "../images/rightArrow.svg"
import SliderContainer from "./Slide"

const images = [
  img1,
  img2,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
]

const expertiseList = images.map((image, index) => {
  return { key: index + 1, icon: image }
})

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={arrowRight}
      alt="next arrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
      className={className}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={arrowLeft}
      alt="prev arrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
      className={className}
    />
  )
}
const StacksSlider = () => {
  const sliderContainer = {
    width: "100%",
    // height: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    justifyContent: "space-evenly",
    alignItems: "center",
    background: "#fff",
    // padding: "60px 0px",
  }
  const itemContent = {
    width: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: "100",
    fontFamily: "Lato",
    fontSize: "9px",
  }
  const iconContainer = {
    width: "300px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: "10px",
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }
  return (
    <div style={sliderContainer}>
      <div style={{ width: "100%" }}>
        <SliderContainer settings={settings}>
          {expertiseList.map(item => (
            <div style={itemContent} key={item.key}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={iconContainer}>
                  <img
                    src={item.icon}
                    style={{ width: "90%", height: "90%" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </SliderContainer>
      </div>
    </div>
  )
}

export default StacksSlider

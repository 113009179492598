import React from "react"
import imgPlace from "../../images/placeholder.png"
import StackCard from "./StackCard"
import Layout from "./Layout"
import backend from "../../images/stacks/backend.png"
import architecture from "../../images/stacks/architecture.png"
import cloud from "../../images/stacks/cloud.png"
import devops from "../../images/stacks/devops.png"
import testing from "../../images/stacks/testing.png"
import mobile from "../../images/stacks/mobile.png"
import ux from "../../images/stacks/ux.png"
import frontend from "../../images/stacks/frontend.png"

const stackList = [
  {
    id: 13,
    title: "BACKEND",
    descriptions: ["40 Consultants", "1 Java Champion"],
    stackImg: backend,
  },
  {
    id: 22,
    title: "FRONTEND & DESKTOP",
    descriptions: ["36 Consultants"],
    stackImg: frontend,
  },
  {
    id: 333,
    title: "MOBILE",
    descriptions: ["6 Consultants"],
    stackImg: mobile,
  },
  {
    id: 44,
    title: "UI/UX",
    descriptions: ["3 Consultants"],
    stackImg: ux,
  },
  {
    id: 53,
    title: "Testing",
    descriptions: ["Experienced QA Engineers"],
    stackImg: testing,
  },
  {
    id: 62,
    title: "DEVOPS",
    descriptions: ["4 Consultants"],
    stackImg: devops,
  },
  {
    id: 71,
    title: "CLOUD & INTEGRATIONS",
    descriptions: ["We'll deploy your app anywhere"],
    stackImg: cloud,
  },
  {
    id: 83,
    title: "Architecture & Audit",
    descriptions: ["6 Solution architect"],
    stackImg: architecture,
  },
]
function WhatWeDoAgile() {
  return (
    <>
      <div >
        {/* <Layout> */}
          {/* <h2 className="mainTitle paddingBottom60" style={{color: "#F0ECF3"}}>Our Expertise</h2> */}
          {/* <p className="subTitle">
            From business requirements to concrete solutions
          </p> */}
          {/* <p className="textP" style={{color: "#B9ABC8"}}>
            Our development teams routinely use an Agile-DevOps approach to
            accelerate solution development, reduce time to market, and foster
            an IT culture of efficient software delivery. xHub experts
            participate in CI/CD implementation, automated testing, and
            infrastructure automation (Infrastructure as Code).
          </p> */}
          <div className="gridFourStack paddingTop30">
            {stackList.map(data => {
              return (
                <StackCard
                  data={data}
                  key={data.id}
                />
              )
            })}
          </div>
        {/* </Layout> */}
      </div>
    </>
  )
}

export default WhatWeDoAgile
